:root {
	--ml-green: rgba(182, 189, 33, 1);
	--ml-dark-gray: rgba(52, 60, 71, 1);
	--ml-light-gray: rgba(120, 132, 144, 1);
	--ml-white: #fff;
	--ml-error: #e3000f;
}

::selection {
	background: var(--ml-green);
	color: var(--ml-dark-gray);
}

::-moz-selection {
	background: var(--ml-green);
	color: var(--ml-dark-gray);
}

.map {
	float: left;
	position: absolute;
	width: 100%;
	height: 100%;
}

.map > .maplibregl-canvas-container >.maplibregl-canvas {
	background-color: var(--ml-dark-gray);
}

.ta-logo {
	position: absolute;
	left: 50px;
	top: 50px;
	height: 80px;
	z-index: 1;
}

@media only screen and (max-width: 900px) {
	.ta-logo {
		left: 20px;
		top: 20px;
		height: 40px;
	}
}

.code {
	float: right;
	width: 25%;
	height: calc(100vh - 70px);
	/* calculate height of the screen minus the heading */
}

.textarea {
	height: 90%;
	width: 100%;
}

textarea {
	line-height: 1.5em;
	font-family: 'Consolas';
	resize: none;
	outline: none;
	border-width: 0px;
	padding: 0px;
}

textarea::selection {
	background: #000000;
	color: #fff;
}

.importBtn {
	background-color: #000000;
	border: 1px solid #000000;
	color: white;
	padding: 5px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	transition-duration: 0.2s;
}

.importBtn:hover {
	background-color: #fff;
	color: #000000;
}

.importBtn:active {
	background-color: #000000;
	color: #fff;
}

.deleteBtn {
	float: left;
	background-color: #fff;
	border: 0px;
	border-radius: 50%;
	height: 30px;
	line-height: 30px;
	width: 30px;
	font-weight: bold;
	color: red;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	transition-duration: 0.2s;
}

.deleteBtn:hover {
	color: #fff;
	background-color: red;
}

.saveBtn {
	float: right;
	background-color: #fff;
	border: 0px;
	border-radius: 50%;
	height: 30px;
	line-height: 30px;
	width: 30px;
	font-weight: bold;
	color: green;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	transition-duration: 0.2s;
}

.saveBtn:hover {
	color: #fff;
	background-color: green;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #f00;
}

.popupAddDependencyBtn {
	display: block;
	width: 100%;
	background-color: #000000;
	border: 1px solid #000000;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 8px;
	cursor: pointer;
	transition-duration: 0.2s;
}


.popupAddDependencyBtn:hover {
	background-color: #fff;
	color: #000000;
}

.popupAddDependencyBtn:active {
	background-color: #000000;
	color: #fff;
}

.popupTopPadding {
	display: block;
	padding-top: 10px;
}

.popup {
	display: grid;
	min-width: 200px;
}

.popupDeleteDependencyBtn {
	background-color: #fff;
	border: 0px;
	border-radius: 50%;
	height: 20px;
	line-height: 20px;
	width: 20px;
	font-weight: bold;
	color: #000000;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	transition-duration: 0.2s;
}

.popupDeleteDependencyBtn:hover {
	color: red;
}

.popupOldIdentifierItem {
	display: none;
}

.popupIdLabel {
	font-weight: bold;
}





/* ================================== */
/* NAVIGATION LIST ITEMS              */
/* ================================== */

.ta-list-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 60px;
	width: 100%;

	/* Variable */
	font-size: 30px;
	height: 80px;
	margin-left: 100px;
	margin-right: 100px;
}

.ta-list-location {
	margin-left: 20px;
	min-width: 60px;
	font-weight: bold;
}

.ta-list-column {
	margin-left: 20px;
	width: 100%;
	min-width: 100px;
}

.ta-list-logo {
	margin-left: 20px;

	/* Variable */
	max-height: 85px;
	max-width: 130px;
}

@media only screen and (max-width: 800px) {
	.ta-list-item {
		margin-left: 20px;
		margin-right: 20px;
		font-size: 16px;
		height: 50px;
	}

	.ta-list-logo {
		margin-left: 20px;
		max-height: 55px;
		max-width: 100px;
	}
}

/* ================================== */
/* NAVIGATION LIST BACK BUTTON        */
/* ================================== */

.ta-menu {
	display: flex;
	flex-direction: row;
	padding-bottom: 500px;
}

.ta-menu-back {
	position: fixed;
	right: 0;
	height: 100%;
	width: 50px;
	background-color: var(--ml-green);
	color: #fff;
	float: right;
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
}

.ta-menu-back > svg {
	position: fixed;
	top: 50%;
}

@media only screen and (max-width: 800px) {
	.ta-menu {
		padding-top: 60px;
	}

	.ta-menu-back {
		right: 10px;
		top: 10px;
		height: 50px;
	}

	.ta-menu-back > svg {
		position: initial;
	}
}

/* ================================== */
/* NAVIGATION TILES DESKTOP           */
/* ================================== */

.ta-nav-menu-anchor-center-right {
	top: calc(50% - 75px);
	right: 10px;
}

.ta-nav-menu-anchor-top-right {
	top: 10px;
	right: 10px;
}

.ta-nav-menu-anchor-bottom-right {
	bottom: 10px;
	right: 10px;
}

.ta-nav-menu {
	color: #000000;
	display: flex;
	flex-direction: row-reverse;
	cursor: pointer;
	pointer-events: none;
	position: absolute;
	z-index: 1;
}

.ta-nav-menu-direction-left {
	flex-direction: row-reverse;
}

.ta-nav-menu-direction-up {
	flex-direction: column-reverse;
}

.ta-nav-menu-direction-down {
	flex-direction: column;
}

.ta-nav-menu:hover {
	pointer-events: all;
}

.ta-nav-start {
	position: relative;
	display: inline-block;
	cursor: pointer;
	height: 150px;
	width: 150px;
	background-color: var(--ml-green);
	pointer-events: all;
}

.ta-nav-item {
	position: relative;
	display: inline-block;
	margin-right: 0px; /* Tile Gap*/
	top: 10px;
	height: 150px;
	width: 150px; 
	background-color: var(--ml-white);
	opacity: 0;
	transition: opacity 0.5s ease, top 0.5s ease;
	transition-delay: 0;
}

span {
	transition: color 0.5s ease;
}

.ta-nav-item:hover span {
	color: var(--ml-green);
	opacity: 1;
}

.ta-nav-item:hover .ta-nav-icon {
	color: var(--ml-green);
}

.ta-nav-item:hover {
	background-color: var(--ml-dark-gray);
}

.ta-nav-menu:hover .ta-nav-item {
	opacity: 1;
	top: 0px;
}

.ta-nav-item:nth-child(1) {
	transition-delay: 0.45s;
}

.ta-nav-item:nth-child(2) {
	transition-delay: 0.4s;
}

.ta-nav-item:nth-child(3) {
	transition-delay: 0.35s;
}

.ta-nav-item:nth-child(4) {
	transition-delay: 0.3s;
}

.ta-nav-item:nth-child(5) {
	transition-delay: 0.25s;
}

.ta-nav-item:nth-child(6) {
	transition-delay: 0.2s;
}

.ta-nav-item:nth-child(7) {
	transition-delay: 0.15s;
}

.ta-nav-item:nth-child(8) {
	transition-delay: 0.1s;
}

.ta-nav-item:nth-child(9) {
	transition-delay: 0.05s;
}

.ta-nav-item:nth-child(10) {
	transition-delay: 0s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(1) {
	transition-delay: 0.25s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(2) {
	transition-delay: 0.3s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(3) {
	transition-delay: 0.35s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(4) {
	transition-delay: 0.4s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(5) {
	transition-delay: 0.45s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(6) {
	transition-delay: 0.5s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(7) {
	transition-delay: 0.55s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(8) {
	transition-delay: 0.6s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(9) {
	transition-delay: 0.65s;
}

.ta-nav-menu:hover .ta-nav-item:nth-child(10) {
	transition-delay: 0.7s;
}

.ta-nav-center {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ta-nav-content {
	position: absolute;
	color: var(--ml-dark-gray);
}

.ta-nav-icon {
	display: block;
	width: 40px !important;
	height: 40px !important;
}

.ta-nav-description {
	display: block;
	font-size: 18px;
	font-weight: bold;
}

.ta-nav-row {
	display: block;
}


/* ================================== */
/* NAVIGATION TILES MOBILE            */
/* ================================== */

@media only screen and (max-width: 800px) {
	.ta-nav-menu {
		flex-direction: column-reverse;
		top: initial;
		bottom: 10px;
		right: 10px;
	}

	.ta-nav-start {
		height: 100px;
		width: 100px;
	}

	.ta-nav-item {
		height: 100px;
		width: 100px;
	}

	.ta-nav-icon {
		display: block;
		width: 20px !important;
		height: 20px !important;
	}

	.ta-nav-description {
		display: block;
		font-size: 12px;
	}
}

.ta-drawer .MuiDrawer-paperAnchorBottom {
	height: 100%;
}

/* ================================== */
/* FINGER PRESS SONAR ANIMATION       */
/* ================================== */

.ta-nav-menu-touch {
	z-index: 2;
	position: absolute;
	right: 95px;
	top: 110px;
}

.ta-nav-menu-touch-icon {
	transform: rotate(30deg);
}

.ta-nav-menu-touch-sonar {
	position: absolute;
	top: 14px;
	right: 25px;
	width: 30px;
	height: 30px;
	border-radius: 9999px;
	background-color: var(--ml-error);
	opacity: 0;
	z-index: -1;
	pointer-events: none;
}

.ta-nav-menu-touch-sonar {
	animation: sonarWave 2s linear infinite;
}

@keyframes sonarWave {
	from {
		opacity: 0.6;
	}

	to {
		transform: scale(2);
		opacity: 0;
	}
}

@media only screen and (max-width: 800px) {
	.ta-nav-menu-touch {
		display: none;
	}
}


/* ================================== */
/* LOCATOR POPUP                      */
/* ================================== */

.ta-locator-popup-box {
	width: 150px;
	background-color: var(--ml-white);
	border: 4px solid var(--ml-error);
	padding: 20px;
	text-align: center;
	font-weight: 900;
	font-size: large;
	color: var(--ml-dark-gray);
	font-family: arial;
	position: relative;
}

.ta-locator-popup-sb:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid var(--ml-error);
	border-top: 10px solid var(--ml-error);
	border-bottom: 10px solid transparent;
	left: -21px;
	top: 6px;
}

.ta-locator-popup-sb:after {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 7px solid transparent;
	border-right: 7px solid var(--ml-white);
	border-top: 7px solid var(--ml-white);
	border-bottom: 7px solid transparent;
	left: -11px;
	top: 10px;
}

.ta-locator-popup > .mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.maplibregl-popup-anchor-left .maplibregl-popup-tip {
	visibility: hidden;
}

.ta-locator-popup > .mapboxgl-popup-content,
.maplibregl-popup-content {
	background: transparent;
	box-shadow: none;
}